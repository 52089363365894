@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Georgian&display=swap');


body {
  margin: 0;
  font-family: 'Noto Sans Georgian', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

::-webkit-scrollbar {
  padding: 0px 4px;
  width: 4px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 99px;
}

